import React, { useState } from "react"
import { MenuItem } from "@material-ui/core"
import colors from "styles/colors"
import { FavoriteStar } from "images/svg"
import { useConfigurationStore, useUiStore } from "hooks/stores"
import { observer } from "mobx-react"
import {
  configurationIconPicker,
  findFavouriteInConfiguration,
} from "utils/helpers"
import { ProgramSelectInput } from "../input/input"
import { SortableHandle } from "react-sortable-hoc"
import {
  StyledMenu,
  StyledSortableHandle,
  FavoriteStarIconWrapper,
  ControlButtons,
  ActionWrapper,
  HeaderWrapper,
  IconWrapper,
} from "./hearing_profile_tile_styles"
import EargoGenerationService from "../../../../../services/eargo_generation_service"

const HearingProfileTileHeader = ({
  hearingProfile: {
    baseProgramLeft,
    baseProgramRight,
    environmentalOffset,
    isHearingAssessmentRes,
  },
  newFavouriteLeftSlot,
  newFavouriteRightSlot,
  index,
  onChange,
}) => {
  const {
    deviceConfiguration: { favouriteLeftSlot, favouriteRightSlot },
    isHearingAssessmentResInTempConfig,
    applyForReset,
    checkIfIsPresetOfIndex,
    newDeviceConfiguration,
    selectInputs,
  } = useConfigurationStore()

  const { showConfigurationModal } = useUiStore()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const setFavouriteButton = (index, side) => {
    const data = {
      target: {
        name: `favourite${side === "left" ? "Left" : "Right"}Slot-${index}`,
        value: index,
      },
    }
    onChange(data)
    handleClose()
  }

  const environmentToShow = () => {
    if (isHearingAssessmentRes) {
      return "NORMAL"
    }
    if (
      environmentalOffset === "NONE" &&
      baseProgramLeft === baseProgramRight
    ) {
      return baseProgramLeft
    } else {
      return environmentalOffset
    }
  }

  const setEnabled = (index) => {
    const data = {
      target: {
        name: `isEnabled-${index}`,
        value: index,
      },
    }
    onChange(data)
    handleClose()
  }

  const DragHandle = SortableHandle(() => <StyledSortableHandle />)

  const checkIfShowChangingFavorites =
    !isHearingAssessmentResInTempConfig && checkIfIsPresetOfIndex(index)

  const {
    foundFavouriteLeft,
    foundFavouriteRight,
  } = findFavouriteInConfiguration(newDeviceConfiguration)
  const checkIfShowDisableProgram =
    !isHearingAssessmentRes &&
    ((newFavouriteRightSlot - 1 !== index &&
      newFavouriteLeftSlot - 1 !== index) ||
      (foundFavouriteLeft !== index && foundFavouriteRight !== index))

  return (
    <HeaderWrapper>
      <IconWrapper>{configurationIconPicker(environmentToShow())}</IconWrapper>

      <ProgramSelectInput
        name={`environmentalOffset-${index}`}
        value={environmentToShow()}
        edit={showConfigurationModal}
        isHearingAssessmentRes={isHearingAssessmentRes}
        onChange={onChange}
        selectInputs={selectInputs[index]}
      />

      {showConfigurationModal
        ? newFavouriteLeftSlot - 1 === index &&
          !isHearingAssessmentResInTempConfig &&
          checkIfIsPresetOfIndex(index) && (
            <FavoriteStarIconWrapper>
              <FavoriteStar color={colors.blue} />
            </FavoriteStarIconWrapper>
          )
        : favouriteLeftSlot - 1 === index &&
          !isHearingAssessmentResInTempConfig &&
          checkIfIsPresetOfIndex(index) && (
            <FavoriteStarIconWrapper>
              <FavoriteStar color={colors.blue} />
            </FavoriteStarIconWrapper>
          )}
      {showConfigurationModal
        ? newFavouriteRightSlot - 1 === index &&
          !isHearingAssessmentResInTempConfig &&
          checkIfIsPresetOfIndex(index) && (
            <FavoriteStarIconWrapper>
              <FavoriteStar color={colors.purple} />
            </FavoriteStarIconWrapper>
          )
        : favouriteRightSlot - 1 === index &&
          !isHearingAssessmentResInTempConfig &&
          checkIfIsPresetOfIndex(index) && (
            <FavoriteStarIconWrapper>
              <FavoriteStar color={colors.purple} />
            </FavoriteStarIconWrapper>
          )}
      {showConfigurationModal &&
        (checkIfShowChangingFavorites || checkIfShowDisableProgram) && (
          <ControlButtons>
            <ActionWrapper
              id={`choose-menu-hearing-profile-tile-${index}`}
              onClick={handleClick}
            />
            <StyledMenu
              id={`choose-menu-hearing-profile-tile-${index}`}
              anchorEl={anchorEl}
              open={Boolean(
                anchorEl &&
                  anchorEl.id === `choose-menu-hearing-profile-tile-${index}`
              )}
              onClose={handleClose}
            >
              {checkIfShowChangingFavorites &&
                EargoGenerationService.canHaveFavorites() && (
                  <MenuItem onClick={() => setFavouriteButton(index, "right")}>
                    {newFavouriteRightSlot - 1 === index &&
                    !isHearingAssessmentRes ? (
                      <>Remove as baseline for R</>
                    ) : (
                      <>Set as baseline for R</>
                    )}
                  </MenuItem>
                )}
              {checkIfShowChangingFavorites &&
                EargoGenerationService.canHaveFavorites() && (
                  <MenuItem onClick={() => setFavouriteButton(index, "left")}>
                    {newFavouriteLeftSlot - 1 === index &&
                    !isHearingAssessmentRes ? (
                      <>Remove as baseline for L</>
                    ) : (
                      <>Set as baseline for L</>
                    )}
                  </MenuItem>
                )}
              {checkIfShowDisableProgram && (
                <MenuItem onClick={() => setEnabled(index)}>
                  Disable program
                </MenuItem>
              )}
            </StyledMenu>
          </ControlButtons>
        )}
      {showConfigurationModal && !applyForReset && !isHearingAssessmentRes && (
        <DragHandle />
      )}
    </HeaderWrapper>
  )
}

export default observer(HearingProfileTileHeader)
