import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { ChargerIcon, HILeft, HIRight } from "images/svg"
import { useSessionStore } from "hooks/stores"
import EargoGenerationService from "../../../services/eargo_generation_service"

const Wrapper = styled.div`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 56%;
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 78%;
    border-bottom: 1px solid rgba(51, 51, 51, 0.17);
  }

  &:last-child::after {
    content: "";
    width: 0;
    border-bottom: none;
  }

  &:first-child::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 1px;
    width: 90%;
    border-bottom: 1px solid rgba(51, 51, 51, 0.17);
  }
`

const ListTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  flex: 0.8;
`

const ListValue = styled.div`
  right: 16px;
  flex: 1;
  word-break: break-all;
  text-align: end;
`

const StyledListItemText = styled(ListItemText)`
  && {
    padding-left: 12px;

    & > span {
      font-size: 14px;
      line-height: 24px;
      font-weight: 800;
    }
  }
`

const StyledListItem = styled(ListItem)`
  && {
    &:first-child {
      margin-top: 8px;
    }

    margin-bottom: 8px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
  }
`

const StyledList = styled(List)`
  && {
    padding-left: 38px;
  }
`

const deviceIcon = (deviceType) => {
  switch (deviceType) {
    case "charger":
      return <ChargerIcon />
    case "hi_left":
      return <HILeft />
    case "hi_right":
      return <HIRight />
    default:
      return <ChargerIcon />
  }
}

const deviceName = (deviceType) => {
  switch (deviceType) {
    case "charger":
      return ""
    case "hi_left":
      return "(LEFT)"
    case "hi_right":
      return "(RIGHT)"
    default:
      return ""
  }
}

const Device = ({
  device: {
    serialNumber,
    serialNumberAscii,
    deviceType,
    name,
    firmwareVersion,
    generation,
    systemSerialNumber,
    firmwareDisplayVersion,
  },
}) => {
  const [expand, setExpand] = useState(true)

  const handleExpand = () => {
    setExpand(!expand)
  }

  return (
    <Wrapper>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem button onClick={handleExpand}>
          {deviceIcon(deviceType)}
          <StyledListItemText
            primary={`${
              name || EargoGenerationService.getCurrentGenerationDisplayValue()
            } ${deviceName(deviceType)}`}
          />
          {expand ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <StyledList component="div" disablePadding>
            {deviceType === "charger" && (
              <StyledListItem>
                <ListTitle>Name</ListTitle>
                <ListValue>{name || "N/A"}</ListValue>
              </StyledListItem>
            )}
            <StyledListItem>
              <ListTitle>Serial number</ListTitle>
              <ListValue>{serialNumberAscii || serialNumber}</ListValue>
            </StyledListItem>
            <StyledListItem>
              <ListTitle>System SN</ListTitle>
              <ListValue>{systemSerialNumber}</ListValue>
            </StyledListItem>
            <StyledListItem>
              <ListTitle>Firmware</ListTitle>
              <ListValue>
                {firmwareVersion || "N/A"} ({firmwareDisplayVersion || "N/A"})
              </ListValue>
            </StyledListItem>
          </StyledList>
        </Collapse>
      </List>
    </Wrapper>
  )
}

export default observer(Device)
