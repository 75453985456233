import { types } from "mobx-state-tree"
import Charger from "./charger/charger"
import Hi from "./hi/hi"

const FirmwareVersions = types.model("FirmwareVersions", {
  charger: types.optional(types.array(Charger), []),
  hi: types.optional(types.array(Hi), []),
})

export default FirmwareVersions
