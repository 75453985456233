import { types } from "mobx-state-tree"
import { sortBy } from "lodash"
import EargoDevice from "../eargo_device/eargo_device"

const EargoSystem = types
  .model("EargoSystem", {
    id: types.identifier,
    serialNumber: types.maybeNull(types.string),
    isActive: types.maybeNull(types.boolean),
    eargoDevices: types.optional(types.map(EargoDevice), {}),
    statistics: types.frozen([]),
    userId: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get eargoDevicesList() {
        return sortBy(Array.from(self.eargoDevices.values()), ["deviceType"])
      },
      get eargoDevicesListWithoutDeactivated() {
        if (!self.eargoDevicesList) return []
        return self.eargoDevicesList.filter(
          (device) => device.status !== "DEACTIVATED"
        )
      },
    }
  })
  .actions((self) => {
    return {
      addEargoDevice({
        id,
        serialNumber,
        serialNumberAscii,
        firmwareVersion,
        firmwareDisplayVersion,
        name,
        isActive,
        deviceType,
        deviceOrigin,
        status,
        generation,
        systemSerialNumber,
        updatedAt,
      }) {
        self.eargoDevices.set(String(id), {
          id: String(id),
          serialNumber,
          serialNumberAscii,
          firmwareVersion,
          firmwareDisplayVersion,
          name,
          isActive,
          deviceType,
          deviceOrigin,
          status,
          generation,
          systemSerialNumber,
          updatedAt,
        })
      },
    }
  })

export default EargoSystem
