import React, { useMemo, useCallback } from "react"
import { Select, MenuItem } from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"

import SoundTuningService from "../../../../../services/sound_tuning_service"
import EargoGenerationService from "../../../../../services/eargo_generation_service"

const HeaderText = styled.div`
  display: inline-block;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin: 16px 0 16px 10px;
`

const StaticValue = styled.div`
  display: inline;
  position: absolute;
  top: 14px;
  font-size: 16px;
  color: ${({ side }) =>
    side === "wide"
      ? `black`
      : side === "right"
      ? `${colors.purple}`
      : `${colors.numberBlue}`};
  right: ${({ side }) => (side === "right" ? `30%` : `10%`)};
`

const StaticSsaValue = styled.div`
  display: inline;
  position: absolute;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin: 5px 0 5px 14px;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`

const InputValue = styled.input`
  position: absolute;
  width: 92px;
  height: 40px;
  border: 1px solid rgba(51, 51, 51, 0.22);
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ side }) =>
    side === "right" ? `${colors.purple}` : `${colors.numberBlue}`};
  right: ${({ side }) => (side === "right" ? `130px` : `20px`)};
  font-size: 16px;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    transform: scale(1.5);
  }
`

const SelectOffsetWrapper = styled.div`
  display: inline-block;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin: 16px 0 16px 10px;
  text-transform: uppercase;

  & div > div {
    width: 126px;
    color: ${({ side }) =>
      side === "right"
        ? `${colors.purple}`
        : side === "left"
        ? `${colors.numberBlue}`
        : `${colors.black}`};
  }

  & div::before {
    border-bottom: none;
  }

  & div:hover {
    border-bottom: none;
  }
`

const SelectWrapper = styled.div`
  display: inline-block;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin: 6px 0 6px 10px;
  text-transform: uppercase;
  position: absolute;
  right: ${({ side }) => (side === "right" ? `130px` : `20px`)};

  & div > div {
    width: ${({ side }) => (side === "wide" ? "202px" : "92px")};
    color: ${({ side }) =>
      side === "right"
        ? `${colors.purple}`
        : side === "left"
        ? `${colors.numberBlue}`
        : `${colors.black}`};

    &.MuiSelect-select {
      padding-right: 0;
    }
  }

  & div::before {
    border-bottom: none;
  }

  & div:hover {
    border-bottom: none;
  }
`

const SelectWrapperSmall = styled.div`
  display: inline-block;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin: 6px 0 6px 10px;
  text-transform: uppercase;
  position: absolute;
  right: 130px;

  & div > div {
    width: ${({ side }) => (side === "wide" ? "202px" : "92px")};
    color: ${({ side }) =>
      side === "right"
        ? `${colors.purple}`
        : side === "left"
        ? `${colors.numberBlue}`
        : `${colors.black}`};

    &.MuiSelect-select {
      padding-right: 0;
    }
  }

  & div::before {
    border-bottom: none;
  }

  & div:hover {
    border-bottom: none;
  }
`

const StyledSelect = styled(Select)`
  && {
    border: 1px solid rgba(51, 51, 51, 0.1);
    box-sizing: border-box;
    border-radius: 4px;

    &:before {
      border-bottom-style: none !important;
    }

    .MuiSelect-select.Mui-disabled {
      border: 1px solid rgba(51, 51, 51, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      background-color: ${colors.lightGrey};
    }
  }
`

const StyledSelectSmall = styled(Select)`
  && {
    border: 1px solid rgba(51, 51, 51, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    width: 92px;

    &:before {
      border-bottom-style: none !important;
    }

    .MuiSelect-select.Mui-disabled {
      border: 1px solid rgba(51, 51, 51, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      background-color: ${colors.lightGrey};
    }
  }
`

const StyledMenuItem = styled(MenuItem)`
  && {
    text-transform: uppercase;
  }
`

const NumberInput = ({ value, edit, side, name, onChange, disabled }) => {
  const { STEP } = useMemo(() => {
    const inputLabel = name.split("-")[0]
    return SoundTuningService.getDefaultDisplayValuesForInput(inputLabel)
  }, [name])

  const handleSoundTuningDisplayValuesRangeCheck = useCallback(
    (...params) => {
      const inputLabel = name.split("-")[0]
      return SoundTuningService.displayValuesRangeCheckFactory(inputLabel)(
        ...params
      )
    },
    [name]
  )

  if (!edit) {
    return <StaticValue side={side}>{value}</StaticValue>
  }

  return (
    <InputValue
      disabled={disabled}
      side={side}
      value={value}
      step={STEP}
      type="number"
      className={`${name} newConfigurationInput`}
      name={name}
      onChange={onChange}
      onInput={handleSoundTuningDisplayValuesRangeCheck}
    />
  )
}

const NOISE_LABELS_BY_GENERATION = {
  EARGO_FIVE: {
    "0": "Off",
    "7": "Low",
    "10": "Medium",
    "13": "High",
  },
  EARGO_SIX: {
    "0": "Off",
    "5": "Low",
    "10": "Medium",
    "15": "High",
  },
  EARGO_SEVEN: {
    "0": "Off",
    "5": "Low",
    "10": "Medium",
    "15": "High",
  },
  EARGO_EIGHT: {
    "0": "Off",
    "5": "Low",
    "10": "Medium",
    "15": "High",
  },
  EARGO_SE: {
    "0": "Off",
    "5": "Low",
    "10": "Medium",
    "15": "High",
  },
}

const NOISE_LABELS_FACTORY = () => {
  return NOISE_LABELS_BY_GENERATION[
    EargoGenerationService.getCurrentGeneration()
  ]
}

const CONVERT_SSA_VALUE_TO_DISPLAY_VALUE = (ssaValue) => {
  return ssaValue === "CLARITY" ? "SPEECH" : ssaValue
}

const options = ["High", "Medium", "Low", "Off"]

const SelectNoiseInput = ({ value, edit, side, name, onChange, disabled }) => {
  if (!edit) {
    return (
      <StaticValue side={side}>
        {NOISE_LABELS_FACTORY()[value.toString()]}
      </StaticValue>
    )
  }
  return (
    <SelectWrapper side={side}>
      <StyledSelect
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        side={side}
      >
        {options.map((option) => {
          const v = parseInt(
            Object.keys(NOISE_LABELS_FACTORY()).find(
              (value) => NOISE_LABELS_FACTORY()[value] === option
            )
          )
          return (
            <MenuItem key={option} value={v}>
              {option}
            </MenuItem>
          )
        })}
      </StyledSelect>
    </SelectWrapper>
  )
}

const SelectFeedbackInput = ({
  value,
  edit,
  side,
  name,
  onChange,
  disabled,
}) => {
  const getTextFromValue = () => {
    switch (value) {
      case false:
        return "Off"
      case true:
        return "On"
      default:
        return
    }
  }
  return !edit ? (
    <StaticValue side={side}>{getTextFromValue()}</StaticValue>
  ) : (
    <SelectWrapper side={side}>
      <StyledSelect
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        side={side}
      >
        <MenuItem value={true}>On</MenuItem>
        <MenuItem value={false}>Off</MenuItem>
      </StyledSelect>
    </SelectWrapper>
  )
}

const SelectBaseline = ({
  value,
  edit,
  side,
  name,
  onChange,
  disabled,
  checkIfShowNormalOnBaselineSelect,
  checkIfIsPresetOfIndex,
}) => {
  return !edit ? (
    <StaticValue side={side}>
      {!checkIfShowNormalOnBaselineSelect
        ? changeProgramDisplayNameToPreset(value, checkIfIsPresetOfIndex)
        : "NORMAL"}
    </StaticValue>
  ) : (
    <SelectWrapper side={side}>
      <StyledSelect
        disabled={checkIfShowNormalOnBaselineSelect ? "NORMAL" : disabled}
        name={name}
        value={checkIfShowNormalOnBaselineSelect ? "NORMAL" : value}
        onChange={onChange}
        side={side}
      >
        {checkIfShowNormalOnBaselineSelect && (
          <MenuItem value={"NORMAL"}>NORMAL</MenuItem>
        )}
        <MenuItem value={"PROGRAM_A"}>
          {checkIfIsPresetOfIndex ? "" : "PRESET 1"}
        </MenuItem>
        <MenuItem value={"PROGRAM_B"}>
          {checkIfIsPresetOfIndex ? "" : "PRESET 2"}
        </MenuItem>
        <MenuItem value={"PROGRAM_C"}>
          {checkIfIsPresetOfIndex ? "" : "PRESET 3"}
        </MenuItem>
        <MenuItem value={"PROGRAM_D"}>
          {checkIfIsPresetOfIndex ? "" : "PRESET 4"}
        </MenuItem>
      </StyledSelect>
    </SelectWrapper>
  )
}

const changeProgramDisplayNameToPreset = (value, checkIfIsPresetOfIndex) => {
  switch (value) {
    case "PROGRAM_A":
      return checkIfIsPresetOfIndex ? "" : "PRESET 1"
    case "PROGRAM_B":
      return checkIfIsPresetOfIndex ? "" : "PRESET 2"
    case "PROGRAM_C":
      return checkIfIsPresetOfIndex ? "" : "PRESET 3"
    case "PROGRAM_D":
      return checkIfIsPresetOfIndex ? "" : "PRESET 4"
    default:
      return value
  }
}

const ProgramSelectInput = ({
  value,
  isHearingAssessmentRes,
  edit,
  name,
  onChange,
  selectInputs,
}) => {
  return (
    <>
      {!edit && (
        <HeaderText>
          {isHearingAssessmentRes
            ? ""
            : changeProgramDisplayNameToPreset(value)}
        </HeaderText>
      )}
      {isHearingAssessmentRes && <HeaderText>NORMAL</HeaderText>}
      {edit && !isHearingAssessmentRes && (
        <SelectOffsetWrapper>
          <StyledSelect
            name={name}
            value={value}
            defaultValue={value}
            onChange={onChange}
          >
            {selectInputs.map((input) => (
              <StyledMenuItem
                disabled={input.disabled}
                value={input.value}
                key={input.value}
              >
                {changeProgramDisplayNameToPreset(input.value)}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </SelectOffsetWrapper>
      )}
    </>
  )
}

const SelectECAInput = ({ value, edit, side, name, onChange, disabled }) => {
  const getTextFromValue = () => {
    switch (value) {
      case false:
        return "Off"
      case true:
        return "On"
      default:
        return
    }
  }
  return !edit ? (
    <StaticValue side={side}>{getTextFromValue()}</StaticValue>
  ) : (
    <SelectWrapper side={side}>
      <StyledSelect
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        side={side}
      >
        <MenuItem value={false}>Off</MenuItem>
        <MenuItem value={true}>On</MenuItem>
      </StyledSelect>
    </SelectWrapper>
  )
}

const SelectSSAValueInput = ({
  value,
  edit,
  side,
  name,
  ssaValue,
  onChange,
  environmentalOffset,
  disabled,
}) => {
  const getTextFromValue = () => {
    switch (value) {
      case "OFF":
        return "Off"
      default:
        return "On"
    }
  }
  const getTextFromSsaValue = () => {
    if (!value || !ssaValue) return ""
    const ssaValueLowercase = CONVERT_SSA_VALUE_TO_DISPLAY_VALUE(
      ssaValue
    ).toLowerCase()

    return ` (${
      ssaValueLowercase[0].toUpperCase() + ssaValueLowercase.slice(1)
    })`
  }

  let ssaItems = []
  if (
    EargoGenerationService.getCurrentGeneration() ===
    EargoGenerationService.GENERATIONS.EARGO_EIGHT
  ) {
    ssaItems.push(
      <MenuItem key="AUTO" value="AUTO">
        Auto
      </MenuItem>
    )
    ssaItems.push(
      <MenuItem key="CLARITY" value="CLARITY">
        Speech
      </MenuItem>
    )
    ssaItems.push(
      <MenuItem key="COMFORT" value="COMFORT">
        Comfort
      </MenuItem>
    )
  }

  return !edit ? (
    <StaticValue side={side}>
      {getTextFromValue() + getTextFromSsaValue()}
    </StaticValue>
  ) : (
    <SelectWrapperSmall side={side}>
      <StyledSelectSmall
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        side={side}
      >
        <MenuItem value="OFF">Off</MenuItem>
        {ssaItems}
      </StyledSelectSmall>
    </SelectWrapperSmall>
  )
}

export {
  NumberInput,
  SelectNoiseInput,
  SelectFeedbackInput,
  ProgramSelectInput,
  SelectBaseline,
  SelectECAInput,
  SelectSSAValueInput,
}
